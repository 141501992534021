<div class="search-container" [class.runnerSearchOnly]="searchRunner" [class.forceFullWidth]="forceFullWidth">
  <div id="searchPanel">
    <mat-autocomplete
      class="autocomplete-panel"
      (optionSelected)="onSearchSelect($event.option.value)"
      #autocom="matAutocomplete"
      connectedTo="#searchElem"
      autoActiveFirstOption="true"
      position="below"
      (opened)="setIsOpenParam(true)"
      (closed)="setIsOpenParam(false)"
    >
      <div id="close">
        <button mat-icon-button (click)="searchClose()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ng-container *ngIf="searchAutocomplete | async; let auto; else: noResult">
        <ng-container
          *ngIf="
            (auto && auto.events && auto.events.length > 0 && !auto.runner) ||
              (auto.runners && auto.runners.length > 0);
            else noResult
          "
        >
          <mat-optgroup
            label="{{ 'RACES' | transloco }}"
            (optionSelected)="optionSelectedEvent.emit($event)"
            *ngIf="auto && auto.events && auto.events.length > 0 && !auto.runner && !searchRunner"
          >
            <mat-option class="!text-center" [autofocus]="false" (click)="goToSearchPage('race')"
              >{{ 'SHOW_MORE_RESULTS' | transloco }} ({{ ('RACES' | transloco) | lowercase }})</mat-option
            >
            <ng-container *ngFor="let event of auto.events">
              <mat-option [value]="event">
                <a
                  [routerLink]="link ? ['/', 'race', event?.trail_alias, event?.event_alias] : []"
                  class="!no-underline"
                >
                  <div class="option-container">
                    <div class="image" [style.background]="event.image_small_url | sanitizeStyleUrl"></div>
                    <div class="trailContent" fxLayout="column" fxLayoutAlign="center">
                      <span class="trail_title">{{ event?.trail_title_original }}</span>
                      <span class="place">
                        <place
                          class="eventPlace"
                          [place]="event?.place"
                          [country]="event?.country"
                          [postal]="event?.postal_code"
                        ></place>
                      </span>
                    </div>
                  </div>
                </a>
              </mat-option>
            </ng-container>
          </mat-optgroup>
          <mat-optgroup label="{{ 'RUNNERS' | transloco }}" *ngIf="auto.runners && auto.runners.length > 0">
            <mat-option *ngIf="!searchRunner" class="!text-center" (click)="goToSearchPage('runner')"
              >{{ 'SHOW_MORE_RESULTS' | transloco }} ({{ ('RUNNERS' | transloco) | lowercase }})</mat-option
            >
            <ng-container *ngIf="auto.runner; let runner">
              <mat-option
                *ngIf="runner._score > 0 && (runner?._source?.hide !== 1 || (isAdmin$ | async))"
                [value]="runner"
              >
                <a [routerLink]="link ? ['/', 'runner', runner.alias] : []" class="!no-underline">
                  <div
                    *ngIf="runner?.avatar"
                    class="image runner"
                    [style.background]="runner?.avatar | sanitizeStyleUrl"
                  ></div>
                  <span
                    class="uppercase"
                    [style.text-decoration]="runner?._source?.hide === 1 ? 'line-through' : 'none'"
                    >{{ runner?.display_title ? runner?.display_title : runner?.title }}
                  </span>
                  <ng-container *ngIf="runner?.nationality">
                    &nbsp; <country-flag [country]="runner.nationality"></country-flag>
                  </ng-container>
                  <span class="score">{{ runner?.bt_level / 100 | number: '2.2-2' }}</span>
                  <span *ngIf="(isAdmin$ | async) || (isEncoder$ |async)">({{ runner.ruid }})</span>
                </a>
              </mat-option>
            </ng-container>

            <ng-container *ngFor="let runner of auto.runners">
              <mat-option
                *ngIf="runner._score > 0 && !searchRunner && (runner?._source?.hide !== 1 || (isAdmin$ | async))"
                [value]="runner"
              >
                <a
                  [routerLink]="isFromMatchup ? './' + runner._source.ruid : link ? '/runner/' + runner._source.alias + '/overview' : ''"
                  class="!no-underline"
                >
                  <div
                    *ngIf="runner?._source?.avatar; else defaultImage"
                    class="image runner"
                    [style.background]="runner?._source?.avatar | sanitizeStyleUrl"
                    [class.user-no-avatar]="runner?._source?.claimed"
                    [class.premium-border]="runner?._source?.is_premium === 1"
                  >
                    <div *ngIf="runner?._source?.is_premium === 1" class="premium-icon"><span>P</span></div>
                  </div>
                  <ng-template #defaultImage>
                    <div
                      class="image runner noAvatar"
                      [class.premium-border]="runner?._source?.is_premium === 1"
                      [class.user-no-avatar]="runner?._source?.claimed"
                    >
                      <ng-container *ngIf="runner?._source?.claimed"
                        >{{ (runner?.display_title ? runner.display_title : runner?.title ||
                        runner?._source?.display_title ? runner._source.display_title : runner?._source.title) |
                        initialsFromTitle | uppercase }}
                      </ng-container>
                      <div *ngIf="runner?._source?.is_premium === 1" class="premium-icon noAvatar"><span>P</span></div>
                    </div>
                  </ng-template>
                  <span
                    class="uppercase"
                    [style.text-decoration]="runner?._source?.hide === 1 ? 'line-through' : 'none'"
                    >{{ runner?.display_title ? runner.display_title : runner?.title || runner?._source?.display_title ?
                    runner._source.display_title : runner?._source.title }}</span
                  >
                  <ng-container *ngIf="runner?._source?.nationality">
                    &nbsp; <country-flag [country]="runner._source.nationality"></country-flag>
                  </ng-container>
                  <span class="score" *ngIf="(runner.bt_level || runner._source.bt_level) > 0"
                    >{{ (runner._source ? runner?._source.bt_level : runner.bt_level) / 100 | number: '2.2-2' }}</span
                  >
                  <span *ngIf="(isAdmin$ | async) || (isEncoder$ |async)" class="runnerId"
                    >&nbsp;(ID {{ runner._source.ruid }})</span
                  >
                </a>
              </mat-option>

              <mat-option
                *ngIf="runner._score > 0 && searchRunner && (runner?._source?.hide !== 1 || (isAdmin$ | async))"
                [value]="runner"
              >
                <a
                  [routerLink]="isFromMatchup ? './' + runner._source.ruid : link ? [runner._id] : []"
                  class="!no-underline"
                >
                  <div
                    *ngIf="runner?._source?.avatar; else defaultImage"
                    class="image runner"
                    [style.background]="runner?._source?.avatar | sanitizeStyleUrl"
                    [class.premium-border]="runner?._source?.is_premium === 1"
                  >
                    <div *ngIf="runner?._source?.is_premium === 1" class="premium-icon"><span>P</span></div>
                  </div>
                  <ng-template #defaultImage>
                    <div class="image runner" [class.premium-border]="runner?._source?.is_premium === 1">
                      <div *ngIf="runner?._source?.is_premium === 1" class="premium-icon"><span>P</span></div>
                    </div>
                  </ng-template>
                  <span [style.text-decoration]="runner?._source?.hide === 1 ? 'line-through' : 'none'"
                    >{{ runner?.display_title ? runner?.display_title : runner?.title || runner?._source.display_title ?
                    runner?._source.display_title : runner?._source.title }}
                  </span>
                  <ng-container *ngIf="runner?._source?.nationality">
                    &nbsp; <country-flag [country]="runner._source.nationality"></country-flag>
                  </ng-container>
                  <span class="score" *ngIf="(runner.bt_level || runner._source.bt_level) > 0"
                    >{{ (runner._source ? runner?._source.bt_level : runner.bt_level) / 100 | number: '2.2-2' }}</span
                  >
                  <span *ngIf="(isAdmin$ | async) || (isEncoder$ |async)" class="runnerId"
                    >&nbsp;(ID {{ runner._source.ruid }})</span
                  >
                </a>
              </mat-option>
            </ng-container>
          </mat-optgroup>

          <!-- <mat-option>Afficher + de résultats</mat-option> -->
        </ng-container>
      </ng-container>
      <ng-template #noResult>
        <ng-container *ngIf="isOpen == true">
          <mat-optgroup label="{{ 'HOME_NORESULT' | transloco }} {{ searchValue | async }}">
            <mat-option> {{ 'SEARCH_RETRY' | transloco }} </mat-option>
          </mat-optgroup>
        </ng-container>
      </ng-template>
    </mat-autocomplete>
  </div>
  <button mat-fab class="search-button small" [class.forceFullWidth]="forceFullWidth" ngClass.lt-md="small">
    <mat-icon>search</mat-icon>
  </button>
  <input
    [class.discreet]="discreet"
    [class.runnerSearchOnly]="searchRunner"
    [class.forceFullWidth]="forceFullWidth"
    #searchElem
    [matAutocomplete]="autocom"
    (click)="$any($event).target.setSelectionRange(0, $any($event).target.value.length)"
    (keyup)="onSearchChange($any($event).target.value)"
    class="search bt-shadowed"
    placeholder="{{ placeholders[0] | transloco }}"
    [class.open]="isOpen"
    spellcheck="false"
    [value]="runnerTwoName"
  />
</div>
